.textInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 36px;

  .label {
    margin-top: 8px;
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    height: 100%;
    min-height: 36px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    font-family: "Nunito Regular";
    color: rgb(70, 71, 74);
    transition: all 0.3s ease-in-out;

    &:focus {
      outline: none;
      border: 1px solid #3e3e3e;
    }
  }
}
