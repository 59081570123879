.cardOptions {
  position: relative;

  .dotButton {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #f5f6fd;
    border: 1px solid #e7e9ff;
    border-radius: 39px;
    outline: none;

    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #898989;
    }
  }
  .optionsContainer {
    position: absolute;
    top: 60px;
    right: 0;

    width: 150px;
    height: auto;

    background: #ffffff;
    border: 1px solid #b1b1b1;
    box-shadow: 0px 4px 36px rgba(53, 54, 54, 0.09);
    border-radius: 5px;

    z-index: 9;

    .option {
      width: 100%;
      height: 40px;
      outline: none;
      border: none;
      background: none;
      transition: all 0.2s ease-in-out;
      font-family: "Nunito Semibold";

      &:hover {
        background: #f5f6fd;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cardOptions {
    .dotButton {
      width: 40px;
      height: 40px;
    }
    .optionsContainer {
      top: 50px;
    }
  }
}
