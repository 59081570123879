.smallButton {
  background-color: #b4b4b4;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  font-family: "Nunito Regular";
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 40px;

  &:hover {
    filter: brightness(1.1);
  }
}
.primary {
  background-color: #f7a907;
}
.secondary {
  background-color: #3e3e3e;
}
.danger {
  background-color: #b43b3b;
}
