.row {
  display: flex;
  justify-content: space-between;
}
.miscsContainer{
    width: 100%;
    padding: 20px;
}
.buttonRow {
  display: flex;
  align-items: center;
  height: 50px;
  column-gap: 100px;
  width: 100%;
  justify-content: space-between;
}
