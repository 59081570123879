.buttonContainer,
.view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 41px;
  margin: auto;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-family: "Nunito Regular";
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    outline: none;
    border: none;
  }

  button:hover {
    background-color: #a4894e;
  }
}

.buttonContainer {
  margin: 2.5px;
  button {
    background-color: #FFD274;
  }
}

.view {
  button {
    background-color: #68c792;
  }
}

@media screen and (max-width: 768px) {
  .buttonContainer {
    width: 100%;
    min-width: 43px;
    height: 40px;

    button {
      width: 100%;
    }
  }
}