.cardContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  position: relative;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    padding-left: 50px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .title {
      font-family: "Nunito Bold";
      color: #000;
      font-size: 24px;
    }
  }
}
.formContainer{
  width: 100%;
}
p{
  margin-bottom: 0;
}
.cardWrapper {
  // justify-content: space-around; /* Adjust as needed */
  align-items: center; /* Adjust as needed */
  padding: 20px;;
  display: flex;
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  flex-flow: row wrap;
  gap: 20px;
}
.buttonWrapper{
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .cardContainer {
    .cardHeader {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
