.notificationContainer {
  width: 295px;
  min-height: 51px;
  box-shadow: 0px 4px 36px rgba(53, 54, 54, 0.46);
  border-radius: 5px;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #FFF;
  z-index: 999;
}
.success {
  background: #189f4e;
}
.error {
  background: #f44336;
}
.hide {
  right: -100%;
  bottom: 30px;
  transition: all 0.5s ease-in-out;
}
.show {
  right: 30px;
  bottom: 30px;
  transition: all 0.5s ease-in-out;
}
