.sidebarContainer {
  width: 300px;
  min-width: 300px;
  min-height: 100vh;
  background-color: #000000;
  padding: 20px;
  position: fixed;
  transition: all 0.3s ease-in-out;

  .closeBtn {
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 25px;
    transition: all 0.3s ease-in-out;

    &:focus {
      transform: rotate(180deg);
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  .logoContainer {
    font-family: "Nunito ExtraBold";
    font-size: 24px;
    color: #fff;
  }
  .navContainer {
    margin-top: 26px;
    .navBody {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: calc(100vh - 170px);
      overflow-y: scroll;
      a {
        width: 100%;
        text-decoration: none;
      }
      .navItem {
        position: relative;
        width: 100%;
        height: 52px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          .navItemIcon {
            background-color: rgb(56, 66, 93);
          }
          .navItemText {
            color: #fff;
          }
          .nextIcon {
            opacity: 1;
          }
        }

        .navItemIcon {
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #3e3e3e;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;
        }

        .navItemText {
          font-family: "Nunito Medium";
          font-size: 14px;
          color: #8b8e96;
          margin-left: 10px;
          font-size: 16px;
          transition: all 0.3s ease-in-out;
        }
        .nextIcon {
          position: absolute;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          right: 20px;
        }
      }
      .active {
        .navItemIcon {
          background-color: rgb(56, 66, 93);
        }
        .navItemText {
          color: #fff;
        }
        .nextIcon {
          opacity: 1;
        }
      }
    }
  }
  .logoutBtn {
    background-color: transparent;
    outline: none;
    border: none;
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-family: "Nunito Medium";
      font-size: 14px;
      color: #8b8e96;
      margin-left: 10px;
      font-size: 16px;
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f12f;
}

@media screen and (max-width: 768px) {
  .sidebarContainer {
    width: 100%;
    left: -100%;
    z-index: 999;
    border-radius: 50%;
  }
  .sidebarOpen {
    left: 0;
    border-radius: 0;
  }
}
