.paginationContainer {
  width: 100%;
  height: 47px;
  border: 1px solid #dddddd;
  border-radius: 360px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 44px;

  .paginationContent {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    align-items: center;
    width: 85%;
    
  }
  .margin {
      margin-top: 15px;
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      align-items: center;
      width: 85%;
    }

  .prevBtn {
    width: 112px;
    border-radius: 360px 0 0 360px;
  }
  .nextBtn {
    width: 112px;
    position: absolute;
    right: 0;
    border-radius: 0 360px 360px 0;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 100%;
    font-family: "Nunito Bold";
    font-size: 14px;
    outline: none;
    border: none;
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #ffd274;
      color: #fff;
    }
  }
  .activeBtn {
    color: #fff;
    background-color: #ffd274;
  }
}
