.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.modal-content {
  width: 80%;
  height: 80%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.video-content {
  width: 100%;
  height: 90%;
  background: black;
  margin-top: 10;
}

.modal-header {
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #444444;
}

button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.footer {
  margin-top: 10px;
}
