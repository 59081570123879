.homeContainer {
  width: calc(100% - 300px);
  position: absolute;
  right: 0;
  padding-bottom: 100px;

  .content {
    padding: 24px 70px;
    .greetingsHeader {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftSection {
        display: flex;
        flex-direction: column;
        font-family: "Nunito Bold";

        .greetings {
          font-size: 18px;
          color: #6c757d;
        }
        .screenName {
          font-size: 24px;
          color: #212529;
        }
      }
      .rightSection {
        display: flex;
        align-items: flex-start;
      }
    }
    .cardsContainer {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 24px;
    }
  }
}
@media screen and (max-width: 768px) {
  .homeContainer {
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
    padding-bottom: 30px;

    .content {
      padding: 24px 20px;

      .greetingsHeader {
        align-items: flex-start;

        .leftSection {
          .greetings {
            font-size: 16px;
          }
          .screenName {
            font-size: 20px;
          }
        }
      }
      .cardsContainer {
        justify-content: center;
      }
    }
  }
}
