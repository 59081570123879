.shortInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .shortInputWrapper {
    display: flex;
    width: 200px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 12px;
    input {
      border: none;
      outline: none;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 10px 0 10px;
      background-color: transparent;
    }
  }
}

.imageUploadContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .imageUploadWrapper {
    display: flex;
    width: 200px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 12px;
    input {
      border: none;
      outline: none;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 10px 0 10px;
      background-color: transparent;
    }
  }
}


.shortInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  column-gap: 50px;
}
.darkContainer {
  background-color: #6e757c !important;

  .timeSelect {
    color: #fff !important;
  }
}
@media screen and (max-width: 768px) {
  .dropDownContainer {
    max-width: 139px;
  }
}
