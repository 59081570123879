.cardContainer {
  border: 1px solid #0000002b;
  border-radius: 5px;
  flex-basis: 120px;
  // justify-content: space-around;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  height: 180px;
  .input{
    display: flex;
    justify-content: end;
    input:hover{
      cursor: pointer;
    }
  }
  .image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    overflow: hidden;
    img{
      object-fit: cover;
      width: 100%;
    }
  }
  .title{
    padding-top: 5px;
  }
}