.cardContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  position: relative;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    padding-left: 50px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .title {
      font-family: "Nunito Bold";
      color: #000;
      font-size: 24px;
    }
  }
  .graphContainer {
    width: 100%;
    height: 400px;
    padding: 20px 50px 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }
  .graphLegendContainer {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    padding-left: 50px;
    column-gap: 150px;
    margin-bottom: 20px;

    .graphLegend {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Nunito Semibold";
      column-gap: 20px;

      .dot {
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }
      .greenDot {
        background-color: #68c792;
      }
      .blueDot {
        background-color: #FFD274;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .cardContainer {
    .cardHeader {
      padding-left: 20px;
    }
    .graphContainer {
      padding: 20px 20px 20px 20px;
    }
    .graphLegendContainer {
      padding-left: 20px;
      column-gap: 50px;
    }
  }
}
