.cardContainer {
  width: 300px;
  height: 120px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .iconContainer {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
    }
  }
  .cardContent {
    display: flex;
    max-width: 149px;
    position: relative;

    .textContent {
      display: flex;
      flex-direction: column;
      color: #000;
      min-width: 149px;

      .title {
        font-family: "Nunito Regular";
        font-size: 16px;
      }
      .value {
        font-family: "Nunito Bold";
        font-size: 22px;
      }
    }
    .iconContent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: 35%;

      img {
        width: 24px;
      }

      .percentage {
        font-family: "Nunito Bold";
        font-size: 22px;
      }
      .green {
        color: #5fd39c;
      }
      .red {
        color: #ef6552;
      }
    }
  }
}
