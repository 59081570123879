@font-face {
  font-family: "Nunito Black";
  src: url("./assets/fonts/Nunito-Black.ttf");
}
@font-face {
  font-family: "Nunito Bold";
  src: url("./assets/fonts/Nunito-Bold.ttf");
}
@font-face {
  font-family: "Nunito ExtraBold";
  src: url("./assets/fonts/Nunito-ExtraBold.ttf");
}
@font-face {
  font-family: "Nunito ExtraLight";
  src: url("./assets/fonts/Nunito-ExtraLight.ttf");
}
@font-face {
  font-family: "Nunito Light";
  src: url("./assets/fonts/Nunito-Light.ttf");
}
@font-face {
  font-family: "Nunito Medium";
  src: url("./assets/fonts/Nunito-Medium.ttf");
}
@font-face {
  font-family: "Nunito Regular";
  src: url("./assets/fonts/Nunito-Regular.ttf");
}
@font-face {
  font-family: "Nunito Semibold";
  src: url("./assets/fonts/Nunito-SemiBold.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
